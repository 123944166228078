import {useModal} from "../../hooks/UseModal";
import trash from "../../assets/icons/trash.png";
import DeactivateSR from "../DeleteSR/DeleteSR";
import {SearchRequest} from "../../context/SearchRequestContext";

function SearchRequestListItem({
                                   id,
                                   searchQuery,
                                   created_at,
                                   refreshed_at,
                                   status,
                                   createdBy,
                                   fullname,
                               }: SearchRequest) {
    const {showModal} = useModal();
    const params = new URLSearchParams(searchQuery);
    const query = {
        keyword: params.get("keyword"),
        location: params.get("location"),
        grade: params.getAll("grade"),
        jobType: params.getAll("jobType")
    };
    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    const createdDate = new Date(created_at).toLocaleDateString("en-US", options);

    const text = `${query.keyword}${
        // @ts-ignore
        query.grade && query.grade.length ? `, ${query.grade.join(", ")}` : ""
    }${
        // @ts-ignore
        query.jobType && query.jobType.length ? `, ${query.jobType.join(", ")}` : ""
    }`;

    const changeToStatusColor = (status: string) => {
        switch (status) {
            case "active":
                return {color: "rgba(85, 170, 51, 1)"};
            case "deactivated":
                return {color: "rgba(103, 103, 103, 1)"};
            default:
                return {color: "rgba(221, 0, 0, 1)"};
        }
    };

    return (
        <tr>
            <td className="query-column">{text}</td>
            <td>{query.location}</td>
            <td>{new Date(created_at).toLocaleDateString("en-US", options)}</td>
            <td>{new Date(refreshed_at).toLocaleDateString("en-US", options)}</td>
            <td style={changeToStatusColor(status)}>{status}</td>
            <td>{fullname}</td>
            <td className="action-body">
                <button
                    onClick={() =>
                        showModal(
                            <DeactivateSR
                                id={id}
                                searchRequest={text}
                                createdAt={createdDate}
                                createdBy={createdBy}
                            />
                        )
                    }
                >
                    <img src={trash} alt="trash"/>
                </button>
            </td>
        </tr>
    );
}

export default SearchRequestListItem;
