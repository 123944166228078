import SearchRequestForm from "../../widgets/SearchRequestForm/SearchRequestForm";
import SearchRequestList from "../../widgets/SearchRequestList/SearchRequestList";

function SearchRequests() {
  return (
    <div>
      <SearchRequestForm />
      <SearchRequestList />
    </div>
  );
}

export default SearchRequests;
