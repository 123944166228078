import React from "react";
import AllResultsListItem, {
  Vacancy,
} from "../../components/AllResultsListItem/AllResultsListItem";

import "./allResultsList.css";

type AllResultsListProps = {
  vacancies: Vacancy[];
};

function AllResultsList({ vacancies }: AllResultsListProps) {
  return (
    <div className="all-results-list">
      <table>
        <thead className="all-results-list-header">
          <tr>
            <th style={{ width: "200px" }}>Job Title</th>
            <th style={{ width: "200px" }}>Search request</th>
            <th style={{ width: "140px" }}>Location</th>
            <th style={{ width: "160px" }}>Company</th>
            <th style={{ width: "140px" }}>Publication date</th>
            <th style={{ width: "120px" }}>Source</th>
          </tr>
        </thead>
        <tbody>
          {vacancies?.map((vacancy) => (
            <AllResultsListItem key={vacancy.id} {...vacancy} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AllResultsList;
