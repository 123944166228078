import { ReactElement, ReactNode, createContext, useState } from "react";
import Modal from "../components/Modal/Modal";

type ModalContextType = {
  showModal: (content: ReactElement) => void;
  closeModal: () => void;
  content: ReactElement | null;
};

const initialState: ModalContextType = {
  showModal: () => {},
  closeModal: () => {},
  content: null,
};

export const ModalContext = createContext<ModalContextType>(initialState);

type ModalProviderProps = {
  children: ReactNode;
};

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [content, setContent] = useState<ReactElement | null>(null);

  const showModal = (modalContent: ReactElement) => setContent(modalContent);
  const closeModal = () => setContent(null);

  return (
    <ModalContext.Provider value={{ showModal, closeModal, content }}>
      {children}
      {content && <Modal closeModal={closeModal}>{content}</Modal>}
    </ModalContext.Provider>
  );
};
