import React from 'react';
import Select, { components, OptionProps, MultiValue } from 'react-select';
import './index.css'
interface OptionType {
    value: string;
    label: string;
}

const Option = (props: OptionProps<OptionType, true>) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                <>&nbsp;</>
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

interface MultiSelectCheckboxProps {
    name: string;
    label: string;
    options: OptionType[];
    value: string[];
    onChange: (selectedValues: string[]) => void;
    placeholder: string;
}

const MultiSelect = ({name, label, options, value, onChange, placeholder }: MultiSelectCheckboxProps) => {
    const handleChange = (selected: MultiValue<OptionType>) => {
        const selectedValues = selected.map(option => option.value);
        onChange(selectedValues);
    };

    const selectedOptions = options.filter(option => value.includes(option.value));

    return (
        <div className="multi-pick-input">
            <label>{label}</label>
        <Select
            isMulti
            key={name}
            options={options}
            value={selectedOptions}
            onChange={handleChange}
            placeholder={placeholder}
            components={{ Option }}
            closeMenuOnSelect={false}
        />
        </div>
    );
};

export default MultiSelect;