import {useContext} from "react";
import {Route, Routes} from "react-router-dom";
import Navigation from "./widgets/Navigation/Navigation";
import SearchRequests from "./pages/SearchRequests/SearchRequests";
import Footer from "./widgets/Footer/Footer";
import {ModalProvider} from "./context/ModalContext";
import {SearchRequestProvider} from "./context/SearchRequestContext";
import {PaginationProvider} from "./context/PaginationContext";
import spinner from "./assets/infinite-spinner.svg";

import "./App.css";
import {KeycloakContext} from "./context/KeycloakContext";
import AllResults from "./pages/AllResults/AllResults";
import AdminPanel from "./pages/AdminPanel/AdminPanel";

function App() {
    const {accessToken} = useContext(KeycloakContext);

    if (!accessToken) {
        return (
            <div className="app-spinner">
                <img src={spinner} alt="Loading"/>
            </div>
        );
    }

    return (
        <div className="App">
            <PaginationProvider>
                <SearchRequestProvider>
                    <ModalProvider>
                        <Navigation/>
                        <Routes>
                            <Route path="/admin-panel" element={<AdminPanel/>}/>
                            <Route path="/all-results" element={<AllResults/>}/>
                            <Route path="*" element={<SearchRequests/>}/>
                        </Routes>
                        <Footer/>
                    </ModalProvider>
                </SearchRequestProvider>
            </PaginationProvider>
        </div>
    );
}

export default App;
