import React, { useId } from "react";
import "./inputWithLabel.css";

import delete_value from "../../assets/icons/delete_value.png";

export type InputWithLabelProps = {
  label: string;
  name: string;
  placeholder: string;
  type?: string;
  value?: string;
  error?: boolean | string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValueRemove?: (name: string) => void;
};

function InputWithLabel({
  label,
  name,
  placeholder,
  type = "text",
  value,
  onChange,
  onValueRemove,
  error,
}: InputWithLabelProps) {
  const id = useId();
  const borderError = error && !value ? "error" : "";

  return (
    <div className="input-with-label">
      <label htmlFor={id}>{label}</label>
      <div className={`${borderError ? "error" : ""}`}>
        <input
          type={type}
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
        {onValueRemove && value && (
          <button onClick={() => onValueRemove(name)}>
            <img src={delete_value} alt="delete value" />
          </button>
        )}
      </div>
    </div>
  );
}

export default InputWithLabel;
