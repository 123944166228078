import React, {useState} from 'react';

// Определение типа для состояния переключателя
type SwitchState = 'on' | 'off';

// Интерфейс для пропсов компонента Switch
interface SwitchProps {
    initialState?: SwitchState;
    onChange?: (state: SwitchState) => void;
}

const Switch: React.FC<SwitchProps> = ({initialState = 'off', onChange}) => {
    const [state, setState] = useState<SwitchState>(initialState);

    const toggle = () => {
        const newState = state === 'on' ? 'off' : 'on';
        setState(newState);
        if (onChange) {
            onChange(newState);
        }
    };

    return (
        <div className={`switch ${state}`} onClick={toggle}>
            <div className={`switch-handle ${state}`}/>
        </div>
    );
};

export default Switch;