import { useEffect, useId, useRef, useState } from "react";
import "./multyPickInput.css";
import delete_value from "../../assets/icons/delete_value.png";
import dropdown from "../../assets/icons/dropdown.png";

export type SelectOption = {
  value: any;
  text: string;
};

export type MultyPickInputProps = {
  label: string;
  name: string;
  value: SelectOption[];
  options: SelectOption[];
  placeholder: string;
  onChange: (name: any, option: SelectOption) => void;
  onValueRemove: (name: any) => void;
};

function SelectedOptions({ text, removeFromSelected }: any) {
  return (
    <div
      className="multy-pick-input_selected-value"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className="multy-pick-input_selected-value-text"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {text}
      </div>
      <button
        className="multy-pick-input_selected-value-btn"
        onClick={(e) => {
          e.stopPropagation();
          removeFromSelected(text);
        }}
      >
        <img src={delete_value} alt="delete value" />
      </button>
    </div>
  );
}

function AvailableOptions({
  option,
  index,
  handleOptionClick,
}: {
  option: SelectOption;
  index: number;
  handleOptionClick: (option: SelectOption) => void;
}) {
  return (
    <div key={index} className="multy-pick-input_dropdown-item-container">
      <input
        className="multy-pick-input_dropdown-item-checkbox"
        type="checkbox"
        checked={option.value}
        onChange={() => {}}
        onClick={() => handleOptionClick(option)}
      />
      <div
        className="multy-pick-input_dropdown-item"
        onClick={() => handleOptionClick(option)}
      >
        {option.text}
      </div>
    </div>
  );
}

function MultyPickInput({
  label,
  placeholder,
  name,
  options,
  onChange,
  onValueRemove,
}: MultyPickInputProps) {
  const id = useId();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false); // open close dropdown
  const [entityName, setEntityName] = useState(""); // search input value
  const [allOptionsSelected, setAllOptionsSelected] = useState(true); // check if all options are selected

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // if all options are removed during closed multipicker
    // this checkbox with all options will be checked
    if (!options.filter((el) => el.value).length) {
      setAllOptionsSelected(true);
    }
  }, [options]);

  const toggleDropdown = (e: any) => {
    setIsOpen(() => {
      setEntityName("");
      return !isOpen;
    });
  };

  const handleOptionClick = (option: SelectOption) => {
    onChange(name, { value: !option.value, text: option.text });
    setAllOptionsSelected(false);
  };

  const handleAllOptionsClick = () => {
    onValueRemove(name);
    setAllOptionsSelected(true);
  };

  const removeFromSelected = (title: string) => {
    onChange(name, { value: false, text: title });
  };

  const showSelected = () => {
    const selectedOption = options.filter((el) => el.value);
    if (!selectedOption.length) {
      return <span>{placeholder}</span>;
    }
    return selectedOption.map((e: SelectOption, index: number) => (
      <SelectedOptions
        key={e.text + index}
        {...e}
        removeFromSelected={removeFromSelected}
      />
    ));
  };

  const showOptions = () => {
    if (!entityName) {
      return options;
    }
    return options.filter((option) =>
      option.text.toLowerCase().includes(entityName.toLowerCase())
    );
  };

  return (
    <div className="select-with-label" ref={dropdownRef}>
      <label htmlFor={id}>{label}</label>
      <div
        onClick={toggleDropdown}
        className={`multy-pick-dropdown-header ${isOpen && "active"}`}
      >
        <div className="multy-pick-input_selected-values-container">
          {isOpen ? <span>Select option</span> : showSelected()}
        </div>
        <div className="multy-pick-input_dropdown-icon">
          <img src={dropdown} alt="dropdown" />
        </div>
      </div>
      {isOpen && (
        <div className="multy-pick-input_dropdown-container">
          <input
            className="multy-pick-input_filter-input"
            type="text"
            value={entityName}
            onChange={(e) => {
              setEntityName(e.target.value);
            }}
          />
          <div className="multy-pick-input_dropdown-item-all-options">
            <input
              type="checkbox"
              checked={allOptionsSelected}
              onChange={() => {}}
              onClick={() => {
                handleAllOptionsClick();
                setEntityName("");
              }}
            />
            <span
              onClick={() => {
                handleAllOptionsClick();
                setEntityName("");
              }}
              className="multy-pick-input_dropdown-item"
            >
              All options
            </span>
          </div>

          {showOptions().map((option, index) => (
            <AvailableOptions
              key={index}
              option={option}
              index={index}
              handleOptionClick={handleOptionClick}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default MultyPickInput;
