import "./allResultsListItem.css";
import { Link } from "react-router-dom";
import shareSVG from "../../assets/icons/share.png";

export type Vacancy = {
  id: number;
  position: string;
  searchQuery: string;
  company: string;
  location: string;
  link: string;
  publishedAt: string;
};

export type AllResultsListProps = {} & Vacancy;

function AllResultsListItem({
  position,
  searchQuery,
  location,
  company,
  publishedAt,
  link,
}: AllResultsListProps) {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const params = new URLSearchParams(searchQuery);
  const query = Object.fromEntries(params);
  const searchRequest = `${query.keyword}${
    query.jobType ? `, ${query.jobType}` : ""
  }${query.grade ? `, ${query.grade}` : ""}`;

  const getSourceFromLink = (link: string) => {
    if (link.includes("linkedin")) {
      return "LinkedIn";
    }
    if (link.includes("indeed")) {
      return "Indeed";
    }
    if (link.includes("welcometothejungle")) {
      return "Welcome to the Jungle";
    }
    return "External link";
  };

  return (
    <tr className="ar-list-item">
      <td
        style={{
          width: "200px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>{position}</span>
        <Link to={link} target="_blank">
          <img style={{ width: "24px" }} src={shareSVG} alt="share" />
        </Link>
      </td>
      <td style={{ width: "200px" }}>{searchRequest}</td>
      <td style={{ width: "140px" }}>{location}</td>
      <td style={{ width: "160px" }}>{company}</td>
      <td style={{ width: "140px" }}>
        {new Date(publishedAt).toLocaleDateString("en-US", options)}
      </td>
      <td style={{ width: "120px" }}>{getSourceFromLink(link)}</td>
    </tr>
  );
}

export default AllResultsListItem;
