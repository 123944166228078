import React, { useEffect, useId, useRef, useState } from "react";
import "./selectWithLabel.css";
import delete_value from "../../assets/icons/delete_value.png";
import dropdown from "../../assets/icons/dropdown.png";

export type SelectOption = {
  value: any;
  text: string;
};

export type SelectWithLabelProps = {
  label: string;
  name: string;
  value: string | number;
  options: { value: string | number; text: string }[];
  onChange: (name: string, option: SelectOption) => void;
  onValueRemove?: (name: string) => void;
};

function SelectWithLabel({
  label,
  name,
  options,
  value,
  onChange,
  onValueRemove,
}: SelectWithLabelProps) {
  const id = useId();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option.text);
    onChange(name, option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!value) {
      setSelectedOption(null);
    }
  }, [value]);

  return (
    <div className="select-with-label" ref={dropdownRef}>
      <label htmlFor={id}>{label}</label>
      <div
        className={`dropdown-header ${isOpen && "active"}`}
        onClick={toggleDropdown}
      >
        <span>{selectedOption || "Select option"}</span>
        {onValueRemove && value && (
          <button
            className="remove-value-btn"
            onClick={() => {
              onValueRemove(name);
              setSelectedOption(null);
            }}
          >
            <img src={delete_value} alt="delete value" />
          </button>
        )}
        <img src={dropdown} alt="dropdown" />
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {options.map((option, index) => (
            <div
              key={index}
              className="dropdown-item"
              onClick={() => handleOptionClick(option)}
            >
              {option.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SelectWithLabel;
