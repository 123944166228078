import React, {useState} from "react";
import "./index.css";

// Определяем тип для компаний (в данном случае строка, но можно расширить типы, если потребуется)
type Company = string;

const CompanyBlacklist: React.FC = () => {
    // Храним массив компаний и значение новой компании
    const [companies, setCompanies] = useState<Company[]>([]);
    const [newCompany, setNewCompany] = useState<string>("");

    // Функция добавления компании в черный список
    const addCompanyToBlacklist = () => {
        if (newCompany.trim()) {
            setCompanies([...companies, newCompany]);
            setNewCompany(""); // Очистить поле после добавления
        }
    };

    // Функция удаления компании из списка
    const removeCompany = (companyToRemove: Company) => {
        setCompanies(companies.filter((company) => company !== companyToRemove));
    };

    return (
        <div className="blacklist-container">
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="🔍 Search by company name"
                    className="search-input"
                    value={newCompany}
                    onChange={(e) => setNewCompany(e.target.value)}
                />
                <button className="add-button" onClick={addCompanyToBlacklist}>
                    Add company to black list +
                </button>
            </div>
            <table className="blacklist-table">
                <thead>
                <tr>
                    <th>Company name</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {companies.length === 0 ? (
                    <tr>
                        <td colSpan={2} className="no-companies">
                            No companies added
                        </td>
                    </tr>
                ) : (
                    companies.map((company, index) => (
                        <tr key={index}>
                            <td>{company}</td>
                            <td>
                                <button
                                    className="delete-button"
                                    onClick={() => removeCompany(company)}
                                >
                                    Remove
                                </button>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </table>
        </div>
    );
};

export default CompanyBlacklist;
