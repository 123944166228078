import { FC } from "react";
import NvPaginationItem from "../NvPaginationItem";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { POSITION_TYPES, usePagination } from "./util";

export interface NvPaginationProps {
  total?: number;
  /**
   * The number of pagination
   * items viewable at a time.
   */
  limit?: number;
  activeItem?: number;
  ariaLabel?: string;
  onPageChange?: (newPage: number) => void;
}
const NvPagination: FC<NvPaginationProps> = ({
  total,
  limit = 7,
  activeItem = 1,
  ariaLabel = "Pagination",
  onPageChange,
}) => {
  const {
    paginationRef,
    pageNumbers,
    position,
    currentActiveItem,
    handleNormalItemClick,
    firstNumber,
    lastNumber,
    setFocusedItem,
    emitPageChangeEvent,
  } = usePagination({
    total,
    limit,
    activeItem,
    onPageChange,
  });

  const FirstItem = () => {
    if (total === undefined) {
      return;
    }
    return (
      <div role="navigation" aria-label={ariaLabel}>
        {total > 1 && (
          <NvPaginationItem
            className={classNames(
              styles["x-arrow-container"],
              styles["x-arrow-container_left"]
            )}
            isPlain={currentActiveItem === firstNumber}
            onClick={(): void => {
              if (currentActiveItem > 1) {
                setFocusedItem(firstNumber);
                emitPageChangeEvent({
                  newPage: currentActiveItem - 1,
                });
              }
            }}
          >
            <span
              className={classNames(styles["x-arrow"], {
                [styles["left"]]: true,
                [styles["disabled"]]: currentActiveItem === firstNumber,
              })}
            />
          </NvPaginationItem>
        )}
      </div>
    );
  };

  const LastItem = () => {
    if (total && total > 1) {
      return (
        <div role="navigation" aria-label={ariaLabel}>
          <NvPaginationItem
            className={classNames(
              styles["x-arrow-container"],
              styles["x-arrow-container_right"]
            )}
            isPlain={currentActiveItem === lastNumber}
            onClick={(): void => {
              if (currentActiveItem < total) {
                setFocusedItem(lastNumber);
                emitPageChangeEvent({
                  newPage: currentActiveItem + 1,
                });
              }
            }}
          >
            <span
              className={classNames(styles["x-arrow"], {
                [styles["right"]]: true,
                [styles["disabled"]]: currentActiveItem === lastNumber,
              })}
            />
          </NvPaginationItem>
        </div>
      );
    }
  };

  const NormalItem = ({ pageNumber }: { pageNumber: number }) => {
    return (
      // Display page number with no placeholders.
      <NvPaginationItem
        key={pageNumber}
        isActive={pageNumber === currentActiveItem}
        onClick={(): void => handleNormalItemClick(pageNumber)}
      >
        {pageNumber}
      </NvPaginationItem>
    );
  };

  const PlaceHolderItem = ({ pageNumber }: { pageNumber: number }) => {
    return (
      <NvPaginationItem key={pageNumber} isPlain={true}>
        ...
      </NvPaginationItem>
    );
  };

  return (
    <div className={classNames(styles["container"])} ref={paginationRef}>
      {total === undefined ? (
        <div role="navigation" aria-label={ariaLabel} />
      ) : (
        <>
          {FirstItem() || null}
          {pageNumbers.map((number, index) => {
            if (limit >= total) {
              return <NormalItem key={number} pageNumber={number} />;
            }
            if (position === POSITION_TYPES.start) {
              return index === pageNumbers.length - 2 ? (
                <PlaceHolderItem key={number} pageNumber={number} />
              ) : (
                <NormalItem key={number} pageNumber={number} />
              );
            }
            if (position === POSITION_TYPES.end) {
              return index === 1 ? (
                <PlaceHolderItem key={number} pageNumber={number} />
              ) : (
                <NormalItem key={number} pageNumber={number} />
              );
            }
            return index === 1 || index === pageNumbers.length - 2 ? (
              <PlaceHolderItem key={number} pageNumber={number} />
            ) : (
              <NormalItem key={number} pageNumber={number} />
            );
          })}
          {LastItem() || null}
        </>
      )}
    </div>
  );
};
export default NvPagination;
