import React, { useContext, useState } from "react";
import "./searchRequestForm.css";
import InputWithLabel from "../../components/InputWithLabel/InputWithLabel";
import Button from "../../components/Button/Button";
import useFetch from "../../hooks/UseFetch";
import { SearchRequestContext } from "../../context/SearchRequestContext";
import { KeycloakContext } from "../../context/KeycloakContext";
import TooltipButton from "../../components/Tooltip";
import MultiSelect from "../../components/MultiSelect";


interface FormState {
    keyword: string;
    grade: string[];
    location: string;
    jobType: string[];
}

const gradeOption = [
    { value: "junior", label: "Junior" },
    { value: "middle", label: "Middle" },
    { value: "senior", label: "Senior" },
];

const jobOption = [
    { value: "remote", label: "Remote" },
    { value: "on site", label: "On site" },
    { value: "hybrid", label: "Hybrid" },
];

const formInitialState: FormState = {
    keyword: "",
    grade: [],
    location: "",
    jobType: [],
};

function SearchRequestForm() {
    const [form, setForm] = useState<FormState>(formInitialState);
    const [error, setError] = useState<Partial<FormState>>({});
    const { sendRequest } = useFetch("search");
    const { fetchFirstPage } = useContext(SearchRequestContext);
    const { accessToken } = useContext(KeycloakContext);

    const requiredFormFields: Array<keyof FormState> = ["keyword", "location"];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (name: "jobType" | "grade", selectedValues: string[]) => {
        setForm({
            ...form,
            [name]: selectedValues,
        });
    };

    const handleClear = (name: keyof FormState) => {
        setForm({ ...form, [name]: "" });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        setError({});
        e.preventDefault();
        const missingValues = checkRequiredFields(form);
        if (missingValues.length) {
            return;
        }
        await sendRequest({
            method: "POST",
            body: { ...form },
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        await fetchFirstPage();

        setForm(formInitialState);
    };
    const checkRequiredFields = (form: FormState) => {
        const missingValues: Array<keyof FormState> = [];
        requiredFormFields.forEach((field) => {
            if (!form[field]) {
                setError((prev) => ({ ...prev, [field]: "This field is required" }));
                missingValues.push(field);
            }
        });
        return missingValues;
    };

    // @ts-ignore
    return (
        <form className="form" onSubmit={handleSubmit}>
            <InputWithLabel
                name="keyword"
                placeholder="Job title, key words"
                label="Keywords*"
                value={form.keyword}
                onChange={handleChange}
                onValueRemove={() => handleClear("keyword")}
                error={error.keyword}
            />
            <MultiSelect
                name="grade"
                label="Grade"
                value={form.grade}
                options={gradeOption}
                onChange={(selected) => handleSelectChange("grade", selected)}
                placeholder="Select grade"
            />
            <MultiSelect
                name="jobType"
                label="Job type"
                value={form.jobType}
                options={jobOption}
                onChange={(selected) => handleSelectChange("jobType", selected)}
                placeholder="Select job type"
            />
            <InputWithLabel
                name="location"
                placeholder="Country, city, or region"
                label="Location*"
                value={form.location}
                onChange={handleChange}
                onValueRemove={() => handleClear("location")}
                error={error.location}
            ></InputWithLabel>
            <Button type="submit">Create request</Button>
            <TooltipButton />
        </form>
    );
}

export default SearchRequestForm;