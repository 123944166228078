import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface usePaginationProps {
  total?: number;
  limit: number;
  activeItem: number;
  onPageChange?: (newPage: number) => void;
}

export const POSITION_TYPES = {
  start: "start",
  middle: "middle",
  end: "end",
};
export function usePagination({
  total,
  limit,
  activeItem,
  onPageChange,
}: usePaginationProps) {
  const [currentActiveItem, setCurrentActiveItem] =
    useState<number>(activeItem);
  const [focusedItem, setFocusedItem] = useState<number | null>(null);
  let pageNumbers = Array.from(Array(total), (_, index) => index + 1);
  const firstNumber = 1;
  const lastNumber = pageNumbers.length;
  const paginationRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (activeItem < firstNumber) {
      setCurrentActiveItem(firstNumber);
    }

    if (activeItem > lastNumber) {
      setCurrentActiveItem(lastNumber);
    }
    setCurrentActiveItem(activeItem);
  }, [activeItem, lastNumber]);

  const position = useMemo(() => {
    if (currentActiveItem < limit - 2) {
      return POSITION_TYPES.start;
    } else if (
      currentActiveItem > pageNumbers[pageNumbers.length - limit + 2]
    ) {
      return POSITION_TYPES.end;
    }
    return POSITION_TYPES.middle;
  }, [currentActiveItem, limit, pageNumbers]);

  pageNumbers = useMemo(
    () =>
      pageNumbers.filter((number, index) => {
        // In each case, in addition to viewable numbers,
        // we need to keep the numbers that eventually
        // will be replaced with a `...` placeholder.
        if (position === POSITION_TYPES.start) {
          return index < limit - 1 || index === pageNumbers.length - 1;
        }
        if (position === POSITION_TYPES.end) {
          return index > pageNumbers.length - limit || index === 0;
        }
        return (
          (number >= currentActiveItem - Math.floor((limit - 3) / 2) &&
            number <= currentActiveItem + Math.ceil((limit - 3) / 2)) ||
          index === 0 ||
          index === pageNumbers.length - 1
        );
      }),
    [currentActiveItem, limit, pageNumbers, position]
  );

  const emitPageChangeEvent = useCallback(
    ({ newPage }: { newPage: number }) => {
      setCurrentActiveItem(newPage);
      onPageChange && onPageChange(newPage);
    },
    [onPageChange]
  );

  const handlePaginationKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === "ArrowLeft" && currentActiveItem > 1) {
        emitPageChangeEvent({
          newPage: currentActiveItem - 1,
        });
      }
      if (ev.key === "ArrowRight" && total && currentActiveItem < total) {
        emitPageChangeEvent({
          newPage: currentActiveItem + 1,
        });
      }
      if (ev.key === "Enter") {
        if (currentActiveItem > 1 && focusedItem === firstNumber) {
          emitPageChangeEvent({
            newPage: currentActiveItem - 1,
          });
        }
        if (total && currentActiveItem < total && focusedItem === lastNumber) {
          emitPageChangeEvent({
            newPage: currentActiveItem + 1,
          });
        }
      }
    },
    [currentActiveItem, focusedItem, emitPageChangeEvent, lastNumber, total]
  );

  const handleNormalItemClick = (pageNumber: number) => {
    emitPageChangeEvent({ newPage: pageNumber });
  };
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (
        paginationRef.current &&
        paginationRef.current.contains(e.target as Node)
      ) {
        setFocusedItem(null);
      }
    },
    [paginationRef]
  );

  useEffect(() => {
    window.addEventListener("keydown", handlePaginationKeyDown);
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("keydown", handlePaginationKeyDown);
      window.removeEventListener("click", handleClick);
    };
  }, [handlePaginationKeyDown, handleClick]);
  return {
    paginationRef,
    pageNumbers,
    position,
    currentActiveItem,
    handleNormalItemClick,
    firstNumber,
    lastNumber,
    setFocusedItem,
    emitPageChangeEvent,
  };
}
