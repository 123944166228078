import { ReactNode, createContext, useState } from "react";

type PaginationContextType = {
  currentPage: number;
  pageChange: (newPage: number) => void;
};

export const PaginationContext = createContext<PaginationContextType>({
  currentPage: 1,
  pageChange: () => {},
});

type PaginationContextProps = {
  children: ReactNode;
};

export const PaginationProvider: React.FC<PaginationContextProps> = ({
  children,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const pageChange = async (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <PaginationContext.Provider
      value={{
        currentPage,
        pageChange,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};
