import { useContext, useEffect } from "react";

import NvPagination from "../../components/NvPagination";
import SearchRequestListItem from "../../components/SearchRequestListItem/SearchRequestListItem";
import {
  SearchRequest,
  SearchRequestContext,
} from "../../context/SearchRequestContext";
import { PaginationContext } from "../../context/PaginationContext";

import "./searchRequestList.css";
import { KeycloakContext } from "../../context/KeycloakContext";

export type SearchRequestListProps = {};

function SearchRequestList() {
  const limit = 15;
  const { searchRequestsData, fetchSearchRequests } =
    useContext(SearchRequestContext);
  const { currentPage, pageChange } = useContext(PaginationContext);
  const { accessToken } = useContext(KeycloakContext);

  useEffect(() => {
    (async () => {
      await fetchSearchRequests({
        method: "GET",
        query: `page=${currentPage}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!searchRequestsData) {
        return;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <div className="list-container">
      <table>
        <thead className="list-header">
          <tr>
            <th>Search request</th>
            <th>Location</th>
            <th>Date of creation</th>
            <th>Date of last refresh</th>
            <th>Status</th>
            <th>Created By</th>
            <th className="action-header">Action</th>
          </tr>
        </thead>
        <tbody>
          {searchRequestsData?.result?.map((sr: SearchRequest) => (
            <SearchRequestListItem key={sr.id} {...sr} />
          ))}
        </tbody>
      </table>

      {searchRequestsData?.totalRecords ? (
        <div className="list-pagination">
          <NvPagination
            total={Math.ceil(searchRequestsData.totalRecords / limit)}
            activeItem={currentPage}
            onPageChange={pageChange}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default SearchRequestList;
