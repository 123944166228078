import React, {useState} from 'react';
import Switch from "../../components/Switch";
import UrlInputComponent from "../../components/UrlInputComponent";
import './index.scss'
import CompanyBlacklist from "../../components/CompanyBlackList";

const AdminPanel: React.FC = () => {
    const [switchState, setSwitchState] = useState<'on' | 'off'>('off');

    const handleSwitchChange = (state: 'on' | 'off') => {
        setSwitchState(state);
        console.log(`Switch is now ${state}`);
    };

    return (
        <div className="app">
            <p className="regular-22">Company file</p>
            <div className="output-container">
                <p className="semibold-13">URL to company list file:</p>
                <div style={{width: '10px', height: '10px'}}></div>
                <UrlInputComponent/>
            </div>
            <p className="regular-22">Notification settings</p>
            <div className="switch-container">
                <Switch initialState={switchState} onChange={handleSwitchChange}/>
                <p className="semibold-13">Receive copies of emails for other Sales managers</p>
            </div>
            <p className="regular-22">Company black list</p>
            <div className="input-container">
                <CompanyBlacklist/>
            </div>
        </div>
    );
};

export default AdminPanel;