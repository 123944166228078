/* eslint-disable react-hooks/exhaustive-deps */
import logo from "../../assets/icons/Logo.png";
import dropdown from "../../assets/icons/dropdown.png";
import {NavLink} from "react-router-dom";

import "./navigation.css";
import {useContext, useEffect, useState} from "react";
import {KeycloakContext} from "../../context/KeycloakContext";

function Navigation() {
    const {accessToken, keycloak, userName} = useContext(KeycloakContext);
    const [imgsrc, setImgsrc] = useState<string>(
        "https://avatars.mds.yandex.net/i?id=29a290a2cb6c3bb2784b9d8bfb2f310ed918ad3d-10807162-images-thumbs&n=13"
    );
    const [showMenu, setShowMenu] = useState<boolean>(false);

    useEffect(() => {
        if (accessToken) {
            fetch(
                `${process.env.REACT_APP_API_URL || "http://localhost:3000"}/cs/pic`,
                {
                    method: "GET",
                    headers: {Authorization: `Bearer ${accessToken}`},
                }
            )
                .then((res) => {
                    return res.arrayBuffer();
                })
                .then((data) => {
                    const blob = new Blob([data], {type: "image/*"});
                    setImgsrc(URL.createObjectURL(blob));
                })
                .catch((err) => {
                    console.log(err);
                    keycloak?.login({prompt: "login"});
                });
        }
    }, [accessToken]);

    const handleLogout = async () => {
        if (!keycloak) {
            return;
        }

        await keycloak.logout({redirectUri: window.location.origin});
    };

    return (
        <nav className="navbar">
            <div className="logo">
                <img src={logo} alt="logo"/>
            </div>
            <div className="navigation">
                <NavLink
                    style={({isActive}) => ({
                        color: isActive ? "var(--color--active)" : "",
                    })}
                    to="/"
                >
                    My searches
                </NavLink>
                <NavLink
                    style={({isActive}) => ({
                        color: isActive ? "var(--color--active)" : "",
                    })}
                    to="/all-results"
                >
                    All Results
                </NavLink>
                <NavLink
                    style={({isActive}) => ({
                        color: isActive ? "var(--color--active)" : "",
                    })}
                    to="/admin-panel"
                >
                    Admin settings
                </NavLink>
            </div>
            <div className="profile">
                <img className="profile-img" src={imgsrc} alt="profile-img"/>

                <div
                    className="profile-name"
                    onClick={() => {
                        setShowMenu(!showMenu);
                    }}
                >
                    {userName}
                </div>
                <button
                    className="profile-dropdown"
                    onClick={() => {
                        setShowMenu(!showMenu);
                    }}
                >
                    <img style={{width: "12px"}} src={dropdown} alt="dropdown"/>
                </button>
                {showMenu && (
                    <div className="profile-menu">
                        <ul className="profile-menu-list">
                            <li>
                                <button className="profile-menu-logout" onClick={handleLogout}>
                                    Logout
                                </button>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </nav>
    );
}

export default Navigation;
