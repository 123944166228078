import { useContext, useEffect, useState } from "react";
import AllResultsList from "../../widgets/AllResultsList/AllResultsList";
import AllResultsFilter, {
  Filter,
} from "../../widgets/AllResultsFilter/AllResultsFilter";
import NvPagination from "../../components/NvPagination";
import { Vacancy } from "../../components/AllResultsListItem/AllResultsListItem";

import "./allResults.css";
import useFetch from "../../hooks/UseFetch";
import { KeycloakContext } from "../../context/KeycloakContext";

const initialFilters = "keywords=&company=&location=&source=";

type VacancyResult = {
  result: Vacancy[];
  totalRecords: number;
};

type VacancyError = {
  message: string;
  statusCode: number;
};

type CompanyResult = string[];

function AllResults() {
  const limit = 15;
  const [vacancies, setVacancies] = useState<Vacancy[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [qs, setQs] = useState(initialFilters);
  const { accessToken, keycloak } = useContext(KeycloakContext);
  const { sendRequest: sendCompaniesRequest } = useFetch(`vacancies/companies`);
  const { sendRequest: sendVacanciesRequest } = useFetch(`vacancies`);

  const sourceOptions = [
    { value: false, text: "Indeed" },
    { value: false, text: "LinkedIn" },
    { value: false, text: "Welcome to the Jungle" },
  ];

  const [filters, setFilters] = useState<Filter>({
    keyword: "",
    companies: [],
    location: "",
    sources: sourceOptions,
    grade: "",
    jobType: "",
  });

  useEffect(() => {
    (async () => {
      const res = (await sendVacanciesRequest({
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })) as VacancyResult & VacancyError;

      if (res.message) {
        console.error(res.message);
        keycloak?.login({ prompt: "login" });
      }

      if (res?.result) {
        keycloak?.updateToken();
        setVacancies(res.result);
        setTotalPages(Math.ceil(res.totalRecords / limit));
      }

      const companies = (await sendCompaniesRequest({
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })) as CompanyResult;

      if (Array.isArray(companies)) {
        setFilters({
          ...filters,
          companies:
            companies?.map((company) => ({
              value: false,
              text: company,
            })) || [],
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageChange = async (newPage: number) => {
    const res = (await sendVacanciesRequest({
      method: "GET",
      query: `${qs}&page=${newPage}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })) as {
      result: Vacancy[];
      totalRecords: number;
    };

    setVacancies(res.result);
    setTotalPages(Math.ceil(res.totalRecords / limit));
    setCurrentPage(newPage);
  };

  const search = async (filters: Filter) => {
    const f: any = {};

    if (filters.keyword) f["keywords"] = filters.keyword;
    if (filters.location) f["location"] = filters.location;
    if (filters.sources.length)
      f["sources"] = filters.sources
        .map((source) => {
          switch (source.text) {
            case "Indeed":
              return "indeed";
            case "LinkedIn":
              return "linkedin";
            case "Welcome to the Jungle":
              return "welcometothejungle";
            default:
              return "";
          }
        })
        .join(",");
    if (filters.companies.length)
      f["companies"] = filters.companies
        .map((company) => company.text)
        .join(",");
    if (filters.grade) f["grade"] = filters.grade;
    if (filters.jobType) f["jobType"] = filters.jobType;

    const qs = new URLSearchParams(f).toString();

    setQs(qs);
    const res = (await sendVacanciesRequest({
      method: "GET",
      query: qs,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })) as {
      result: Vacancy[];
      totalRecords: number;
    };

    setVacancies(res.result);
    setTotalPages(Math.ceil(res.totalRecords / limit));
    setCurrentPage(1);
  };

  return (
    <div className="all-results-container">
      <p>
        All results are kept in database for 30 days since the date of
        publication
      </p>

      <AllResultsList vacancies={vacancies}></AllResultsList>

      {filters.companies.length === 0 ? null : (
        <AllResultsFilter
          search={search}
          filters={filters}
          setFilters={setFilters}
        />
      )}
      {!totalPages ? null : (
        <div style={{ margin: "0 auto" }}>
          <NvPagination
            total={totalPages}
            activeItem={currentPage}
            onPageChange={pageChange}
          ></NvPagination>
        </div>
      )}
    </div>
  );
}

export default AllResults;
