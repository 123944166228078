import React, { useState } from "react";
import "./index.scss";
import close from "../../assets/icons/close.svg";
import check from "../../assets/icons/check.svg";
import splash from "../../assets/icons/splash.svg";

const UrlInputComponent: React.FC = () => {
  const [url, setUrl] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);
  const isTyping = !!url.length;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUrl(value);
    setIsValid(validateUrl(value));
  };

  const validateUrl = (value: string): boolean => {
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return urlPattern.test(value);
  };

  const handleAccept = () => {
    if (isValid) {
      alert("URL accepted: " + url);
    } else {
      setUrl("");
      alert(
        "You can only enter data as a URL link (example: http://www.filelocation.com)",
      );
    }
  };

  const handleReject = () => {
    setUrl("");
    setIsValid(false);
  };

  return (
    <div className="input-with-buttons">
      <input
        type="text"
        value={url}
        onChange={handleChange}
        placeholder="Enter link to file"
        className="url-input"
      />
      {isTyping ? (
        <>
          <img src={splash} alt={"SplashIcon"} />
          <button onClick={handleAccept} className="button-left">
            <img src={check} alt={"CheckIcon"} />
          </button>
          <button onClick={handleReject} className="button-right">
            <img src={close} alt={"CloseIcon"} />
          </button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UrlInputComponent;
