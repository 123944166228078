import React, { useEffect, useState } from "react";
import InputWithLabel from "../../components/InputWithLabel/InputWithLabel";
import { useDebounce } from "../../hooks/UseDebounce";
import MultyPickInput, {
  SelectOption,
} from "../../components/MultyPickInput/MultyPickInput";
import SelectWithLabel from "../../components/SelectWithLabel/SelectWithLabel";

export type Filter = {
  keyword: string;
  companies: SelectOption[];
  location: string;
  sources: SelectOption[];
  grade: string;
  jobType: string;
};

type AllResultsFilterProps = {
  // eslint-disable-next-line no-empty-pattern
  search: ({}: any) => void;
  filters: Filter;
  setFilters: (value: React.SetStateAction<Filter>) => void;
};

function AllResultsFilter({
  search,
  filters,
  setFilters,
}: AllResultsFilterProps) {
  const debouncedKeywordsValue = useDebounce(filters.keyword, 500);
  const debouncedCompanyValue = useDebounce(filters.companies, 500);
  const debouncedLocationValue = useDebounce(filters.location, 500);
  const debouncedSourceValue = useDebounce(filters.sources, 500);
  const debouncedGradeValue = useDebounce(filters.grade, 500);
  const debouncedJobTypeValue = useDebounce(filters.jobType, 500);

  const [mountPassed, setMountPassed] = useState(false);

  useEffect(() => {
    setMountPassed(true);

    if (mountPassed) {
      search({
        keyword: debouncedKeywordsValue,
        companies: debouncedCompanyValue.filter(
          (company: any) => company.value
        ),
        location: debouncedLocationValue,
        sources: debouncedSourceValue.filter((source: any) => source.value),
        grade: debouncedGradeValue,
        jobType: debouncedJobTypeValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedKeywordsValue,
    debouncedCompanyValue,
    debouncedLocationValue,
    debouncedSourceValue,
    debouncedGradeValue,
    debouncedJobTypeValue,
  ]);

  const setInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (name: string, option: SelectOption) => {
    setFilters({ ...filters, [name]: option.value });
  };

  const handleClear = (name: string) => {
    setFilters({ ...filters, [name]: "" });
  };

  const handleMultiPick = (
    name: "companies" | "sources",
    option: SelectOption
  ) => {
    setFilters((prev) => {
      return {
        ...prev,
        [name]: prev[name].map((el) => (el.text === option.text ? option : el)),
      };
    });
  };

  const handleMultiPickClear = (name: "companies" | "sources") => {
    setFilters({
      ...filters,
      [name]: filters[name].map((el) => ({ ...el, value: false })),
    });
  };

  return (
    <div
      style={{
        width: "240px",
        display: "flex",
        gap: "30px",
        flexDirection: "column",
      }}
    >
      <InputWithLabel
        label="Keywords"
        name="keyword"
        placeholder="Job title, key words"
        value={filters.keyword}
        onChange={setInput}
        onValueRemove={handleClear}
      />
      <MultyPickInput
        label="Company"
        name="companies"
        placeholder="Company name"
        value={filters.companies}
        options={filters.companies}
        onChange={handleMultiPick}
        onValueRemove={handleMultiPickClear}
      />
      <InputWithLabel
        label="Location"
        name="location"
        placeholder="Country, city or region"
        value={filters.location}
        onChange={setInput}
        onValueRemove={handleClear}
      />
      <MultyPickInput
        label="Source"
        name="sources"
        placeholder="Source name"
        value={filters.sources}
        options={filters.sources}
        onChange={handleMultiPick}
        onValueRemove={handleMultiPickClear}
      />
      <SelectWithLabel
        name="grade"
        label="Grade"
        value={filters.grade || ""}
        options={[
          { value: "junior", text: "Junior" },
          { value: "middle", text: "Middle" },
          { value: "senior", text: "Senior" },
        ]}
        onChange={handleSelectChange}
        onValueRemove={handleClear}
      />
      <SelectWithLabel
        name="jobType"
        label="Job type"
        value={filters.jobType || ""}
        options={[
          { value: "remote", text: "Remote" },
          { value: "on site", text: "On site" },
          { value: "hybrid", text: "Hybrid" },
        ]}
        onChange={handleSelectChange}
        onValueRemove={handleClear}
      />
    </div>
  );
}

export default AllResultsFilter;
