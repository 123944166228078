import React, {useState} from 'react';
import './Tooltip.scss';
import info from '../../assets/icons/info.svg'

const TooltipButton = () => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className="tooltip-container">
            <img src={info} alt={'info'}
                 onMouseEnter={() =>
                     setShowTooltip(true)}
                 onMouseLeave={() => setShowTooltip(false)}
            >
            </img>
            {showTooltip && (
                <div className="tooltip">
                    <h3><b>Field “Position, Keywords”</b></h3>
                    <p>&nbsp;</p>
                    <p><b>*Required field</b></p>
                    <p> One or several keywords with “space” as a separator. </p>
                    <p>&nbsp;</p>
                    <p>Firstly, a complete match search will occur.</p>
                    <p>After complete match search, partial search will occur.</p>
                    <p>Not on all platforms partial search results can be obtained. For example, partial search works on
                        WTTJ, but not on Indeed)</p>
                    <p>&nbsp;</p>
                    <p><b>Example:</b></p>
                    <p>QA Python Node</p>
                    <p>&nbsp;</p>
                    <p><b>Results:</b></p>
                    <p>Primary results: all found results matching “QA Python Node”</p>
                    <p>Other results: QA / QA Python / QA Node / Python Node / Node / Python”</p>
                    <p>&nbsp;</p>
                    <h3><b> Field “Grade”</b></h3>
                    <p>&nbsp;</p>
                    <h3><b> Optional field</b></h3>
                    <p>If not indicated, all the results will return - Junior, Middle, Senior</p>
                    <p>&nbsp;</p>
                    <p><b> Field “Job type”</b></p>
                    <p>&nbsp;</p>
                    <h3><b>Optional field</b></h3>
                    <p> If not indicated, all the results will return - Remote, On site, Hybrid</p>
                    <p>&nbsp;</p>
                    <h3><b>Field “Location”</b></h3>
                    <p>&nbsp;</p>
                    <p><b> *Required field</b></p>
                    <p>Only 1 location should be indicated.</p>
                    <p>&nbsp;</p>
                    <p><b> Example:</b></p>
                    <p> “Paris” / “France” / “Madrid”</p>
                </div>
            )}
        </div>
    );
};

export default TooltipButton;