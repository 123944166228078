import "./button.css";

export type ButtonProps = {
  children: React.ReactNode;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
};

function Button({
  children,
  className = "",
  onClick,
  disabled,
  type,
}: ButtonProps) {
  return (
    <button
      className={"button " + className}
      disabled={disabled}
      onClick={onClick}
      type={type || "button"}
    >
      {children}
    </button>
  );
}

export default Button;
