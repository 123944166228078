/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, createContext, useContext, useEffect } from "react";
import useFetch, { FetchOptions } from "../hooks/UseFetch";
import { PaginationContext } from "./PaginationContext";
import { KeycloakContext } from "./KeycloakContext";

export type SearchRequest = {
  id: number;
  status: string;
  created_at: string;
  refreshed_at: string;
  searchQuery: string;
  createdBy: string;
  fullname: string;
};

type SearchRequestData = {
  totalRecords: number;
  result: SearchRequest[];
  message?: string;
  statusCode?: number;
};

type SearchRequestContextType = {
  searchRequestsData: SearchRequestData | null;
  fetchSearchRequests: (options: any) => void;
  fetchFirstPage: () => void;
};

export const SearchRequestContext = createContext<SearchRequestContextType>({
  searchRequestsData: null,
  fetchSearchRequests: () => {},
  fetchFirstPage: () => {},
});

type SearchRequestProviderProps = {
  children: ReactNode;
};

export const SearchRequestProvider: React.FC<SearchRequestProviderProps> = ({
  children,
}) => {
  const { accessToken } = useContext(KeycloakContext);
  const { data: searchRequestsData, sendRequest } =
    useFetch<SearchRequestData>("search");
  const { pageChange } = useContext(PaginationContext);
  const { keycloak } = useContext(KeycloakContext);

  const fetchSearchRequests = async (options: FetchOptions<any>) => {
    await sendRequest(options);
  };

  const fetchFirstPage = async () => {
    await sendRequest({
      method: "GET",
      query: `page=${1}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    pageChange(1);
  };

  useEffect(() => {
    if (searchRequestsData && searchRequestsData.message) {
      keycloak?.login({ prompt: "login" });
    }
  }, [searchRequestsData]);

  return (
    <SearchRequestContext.Provider
      value={{
        searchRequestsData,
        fetchSearchRequests,
        fetchFirstPage,
      }}
    >
      {children}
    </SearchRequestContext.Provider>
  );
};
