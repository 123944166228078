import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import styles from "./styles.module.scss";

export interface NvPaginationItemProps {
  isActive?: boolean;
  isPlain?: boolean;
  href?: string;
  ariaLabel?: string;
  className?: string;
  onClick?: () => void;
}

const NvPaginationItem: FC<PropsWithChildren<NvPaginationItemProps>> = ({
  isActive = false,
  isPlain = false,
  href,
  className,
  ariaLabel = "",
  children,
  onClick = () => {},
}) => {
  const Component = () => {
    if (!href || isActive || isPlain) {
      return children;
    }
    return (
      <a href={href} tabIndex={href ? 0 : undefined}>
        {children}
      </a>
    );
  };
  return (
    <div
      aria-label={ariaLabel}
      aria-current={isActive ? "page" : undefined}
      className={classNames(
        styles["nv-pagination-item"],
        styles["text"],
        className,
        {
          [styles["plain"]]: isPlain,
          [styles["active"]]: isActive,
        }
      )}
      onClick={onClick}
    >
      {Component() || null}
    </div>
  );
};
export default NvPaginationItem;
