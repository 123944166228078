import { useState, useCallback } from "react";

export type FetchOptions<T> = {
  method: "GET" | "POST" | "PUT" | "DELETE";
  headers?: HeadersInit;
  body?: T;
  query?: string;
};

type UseFetchReturn<T> = {
  data: T | null;
  sendRequest: (options: FetchOptions<T>) => Promise<T | void>;
  loading: boolean;
  error: Error | null;
};

function useFetch<T>(path: string): UseFetchReturn<T> {
  const host = process.env.REACT_APP_API_URL || "http://localhost:3000";
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const sendRequest = useCallback(
    async (options: FetchOptions<T> = { method: "GET" }): Promise<T | void> => {
      setLoading(true);
      setError(null);

      try {
        const { method, headers, body, query } = options;
        const url = `${host}/${path}${query ? `?${query}` : ""}`;
        const response = await fetch(url, {
          credentials: "include",
          method,
          headers: { "Content-Type": "application/json", ...headers },
          body: body && JSON.stringify(body),
        });

        if (!response.ok) {
          console.error(`HTTP error! Status: ${response.status}`);
        }

        if (method !== "GET" && response.status === 204) {
          return;
        }

        if (method !== "DELETE" || response.status !== 204) {
          const jsonData: T = await response.json();
          setData(jsonData);
          return jsonData;
        }
      } catch (err) {
        setError(err instanceof Error ? err : new Error("An error occurred"));
      } finally {
        setLoading(false);
      }
    },
    [host, path]
  );

  return { data, sendRequest, loading, error };
}

export default useFetch;
