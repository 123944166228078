import { useContext } from "react";
import { useModal } from "../../hooks/UseModal";
import Button from "../Button/Button";
import "./deleteSR.css";
import { SearchRequestContext } from "../../context/SearchRequestContext";
import useFetch from "../../hooks/UseFetch";
import { KeycloakContext } from "../../context/KeycloakContext";

type DeleteSRProps = {
  id: number;
  searchRequest: string;
  createdAt: string;
  createdBy: string;
};

function DeleteSR({ id, searchRequest, createdAt, createdBy }: DeleteSRProps) {
  const { closeModal } = useModal();
  const { fetchFirstPage } = useContext(SearchRequestContext);
  const { accessToken } = useContext(KeycloakContext);
  const { sendRequest } = useFetch(`search/${id}`);

  const deleteSearchRequest = async () => {
    await sendRequest({
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    await fetchFirstPage();
    closeModal();
  };

  return (
    <div className="dsr-container">
      <div className="dsr-title">Delete request?</div>
      <p>
        <span className="dsr-bold">Request ID: </span>
        {id}
      </p>
      <p>
        <span className="dsr-bold">Search request: </span>
        {searchRequest}
      </p>
      <p>
        <span className="dsr-bold">Date of creation: </span>
        {createdAt}
      </p>
      <p>
        <span className="dsr-bold">Created by: </span>
        {createdBy}
      </p>
      <p className="dsr-message">
        If you delete this request all the search results found for this request
        will be deleted <span className="dsr-bold">permanently.</span>
      </p>
      <div className="dsr-menu">
        <Button className="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button className="danger" onClick={deleteSearchRequest}>
          Delete
        </Button>
      </div>
    </div>
  );
}

export default DeleteSR;
