import { ReactNode } from "react";
import "./modal.css";

type ModalProps = {
  children: ReactNode;
  closeModal: () => void;
};

function Modal({ children, closeModal }: ModalProps) {
  return (
    <div className="modal-wrapper" onClick={closeModal}>
      <div className="modal-body" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
}

export default Modal;
